import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a2daaa04"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "produktion" }
const _hoisted_2 = {
  key: 0,
  class: "text-center"
}
const _hoisted_3 = {
  key: 1,
  class: "w-100"
}
const _hoisted_4 = {
  key: 1,
  class: "row"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingSpinner = _resolveComponent("LoadingSpinner")!
  const _component_MaschineLeistungCard = _resolveComponent("MaschineLeistungCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.loginlagerLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_LoadingSpinner)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_ctx.loading)
            ? (_openBlock(), _createBlock(_component_LoadingSpinner, {
                key: 0,
                text: '...Daten werden geladen...'
              }, null, 8, ["text"]))
            : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.maschinen, (maschine) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: maschine.id,
                    class: "col-3 mb-3"
                  }, [
                    _createVNode(_component_MaschineLeistungCard, {
                      maschine: maschine,
                      maschinenplanung: _ctx.maschinenPlanungHeute.find(m => m.maschinennr == maschine.maschinenr)
                    }, null, 8, ["maschine", "maschinenplanung"])
                  ]))
                }), 128))
              ]))
        ]))
  ]))
}