import axios, { AxiosResponse } from "axios";
import {setupInterceptorsTo} from './PersonalApiInterceptors';

/* DEV */
// import config from '../../appsettings.dev.json'

/* PROD */
const appsettings = await fetch('/appsettings.json')
const config = await appsettings.json() 

const BASE_URL = config.personalapi.apiUrl;
const API_KEY = config.personalapi.apiKey;

const apiClient = setupInterceptorsTo(axios.create({
  baseURL: BASE_URL,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'ApiKey': API_KEY
  }
}));


export default {
  getCurrentlyWorkingMitarbeiter(): Promise<AxiosResponse> {
    return apiClient.get('/arbeitszeit/currentlyworking')
  },
}