import axios, { AxiosResponse } from "axios";
import {setupInterceptorsTo} from './ArchivApiInterceptors';

import { LoginRequest, RefreshLoginRequest, LogoutRequest } from '@/models/ArchivModels';

/* DEV */
// import config from '../../appsettings.dev.json'

/* PROD */
const appsettings = await fetch('/appsettings.json')
const config = await appsettings.json() 

const BASE_URL = config.archivapi.apiUrl;
const API_KEY = config.archivapi.apiKey;

const apiClient = setupInterceptorsTo(axios.create({
  baseURL: BASE_URL,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'ApiKey': API_KEY
  }
}));


export default {
  login(loginRequest: LoginRequest): Promise<AxiosResponse> {
    return apiClient.post('/login', loginRequest)
  },

  loginAzure(idToken: String): Promise<AxiosResponse> {
    const config = {
      headers: {
        "idtoken": idToken.toString()
      }
    }

    return apiClient.get('/login/azure', config);
  },

  refreshLogin(data: RefreshLoginRequest) {
    return apiClient.post("/login/refresh", data)
  },

  logout(data: LogoutRequest): Promise<AxiosResponse> {
    return apiClient.post('/logout', data);
  },

  getAzureLoginConfig(): Promise<AxiosResponse> {
    return apiClient.get('/login/azureconfig')
  },
}